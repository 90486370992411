.avatarContainerBlk {
    position: relative;
    width: 25px;
    height: 25px;
    cursor:pointer;
}

.avatarContainerBlk img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display:block;
    outline: 2px solid #122a53;
    object-fit: cover;
}