.leaderboardListContainer {
    padding: 0px 20px;
}

.leaderboardListContainer>.leaderboardCard:last-child {
    margin-bottom: 0px;
    padding-bottom: 20px;
}

.leaderboardCard {
    margin-bottom: 15px;
    display:flex;
    align-items: center;
    gap:5px;
}


.leaderboardLinkText  {
    text-decoration: none;
    display: inline-block;
}


.leaderboardLinkText h3 {
    transition: color 0.3s ease-in-out;
}

.leaderboardLinkText:hover h3 {
    color: #01C2F3;
}

.leaderboardCard img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}

.leaderboardCard h3 {
    font-size: 11px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 3px;
}

.avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}


.nameSection {
    text-transform: capitalize;
}