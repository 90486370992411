.commentsModalContainer {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
}

.commentsModalMain {
    width: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 5%;
}

.commentsModalMain i {
    position: absolute;
    right: 20px;
    top: 14px;
    font-size: 20px;
    cursor: pointer;
}

.commentsModalMain h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 20px;
    border-bottom: 1px solid #2B315C;
    padding-bottom: 15px;
}

.commentModalHeaderBlk {
    padding-top: 15px;
    padding-bottom: 15px;
    display:flex;
    gap: 10px;
    align-items: center;
}

.pronounSectionBlk p {
    font-weight: 400;
    color: #426093;
    font-size: 9px;
}

.commentModalHeaderBlk h3 {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}

.messageSectionBlk {
    max-height: 70px;
    padding-right: 5px;
    overflow-y: auto;
    font-weight: 200;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #fff;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom:10px;
}

.emptyOnSubmit {
    border: 1px solid #9b1e1e;
}

.messageSectionBlk:focus{
   outline: none;
}

.flexBoxSectionName {
    display:flex;
    gap: 7px;
    align-items: center;
}

.flexBoxSectionName span {
    color: #426093;
    font-size: 9px;
}

.messageSectionBlk::-webkit-scrollbar {
    width: 10px;
}

.messageSectionBlk::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.messageSectionBlk::-webkit-scrollbar-thumb {
    background: rgb(18, 50, 101);
    border-radius: 10px;
    cursor: pointer;
}

.containerCommentListBlk {
    display:flex;
    gap: 10px;
}

.containerCommentListBlk>div:nth-child(2) {
   width: 100%;
}

.containerCommentListBlk p {
    font-size:12px;
    font-weight: 200;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;

}

.avatarSectionListCommentBlk {
    width: 23px;
    height: 23px;
    display:block;
    object-fit: cover;
    border-radius: 50%;
}

.modalCommentListContainerBlk {
    max-height: 120px;
    padding-right:5px;
    overflow-y: auto;
}

.modalCommentListContainerBlk::-webkit-scrollbar {
    width: 10px;
}

.modalCommentListContainerBlk::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.modalCommentListContainerBlk::-webkit-scrollbar-thumb {
    background: rgb(18, 50, 101);
    border-radius: 10px;
    cursor: pointer;
}

.commentListContainerBlk {
    background:#0D1F3E;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.commentListContainerBlk:last-child {
    margin-bottom: 0px;
}

.commentListContainerBlk:last-child {
    margin-bottom: 0px;
}

.postCommentBlkSection {
    display:flex;
    background:#0D1F3E;
    margin-top: 10px;
    padding: 10px;
    gap:10px;
    border-radius: 5px;
    margin-bottom: 25px;
}

.avatarCommentSection div {
    border-radius: 50%;
    width:25px;
    height:25px;
}

.commentPostSectionBlk {
    flex: 1;
    border-radius: 5px;
    background:#09172E;
    padding: 0px 0px 0px 10px;
}

.commentPostSectionBlk  textarea{
   height:50px;
}

.beTheFirstComment{
    font-size: 12px;
    color: #01C2F3;
}

.flexBoxBlkCommentList {
    display:flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.flexBoxBlkCommentList>div:nth-child(2) {
  margin-left: auto;
}

.flexBoxBlkCommentList h3 {
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}

.flexBoxBlkCommentList p {
    display: block;
    color: #586164;
    font-size: 11px;
    font-weight: 400;
}

.commentPostFooter {
    display:flex;
    align-items: center;
    padding: 0px 0px 0px 0px;
}

.commentPostFooter>div:last-child {
    padding: 10px;
    margin-left: auto;
}

.buttonSubmit {
    background: #FECF0C;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.buttonSubmit:hover {
    background: #ffd52d;
}

.cancelButton {
    background:none;
    color:#fff;
    padding: 10px;
    border:0px;
}

.cancelButton:focus {
    outline:none;
}

.avatarImageSection img {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    object-fit: cover;
    display:block;
}

.dateSectionBlk {
    margin-left: auto;
}

.dateSectionBlk p {
    color: #586164;
    font-weight: 400;
    font-size: 11px;
}

.avatarCommentSection div {
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.avatarCommentSection img {
    border-radius: 50%;
    display:block;
    object-fit:cover;
    width:23px;
    height:23px;
}

.ellipsisSectionPost {
    color: #fff;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
}


.deletePrompt {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.deleteIcon{
    width: 40px;
    margin: 0 auto 10px auto;
    display: block;
}



.ellipsisSection {
    color: #fff;
    margin-left: 5px;
    position:relative;
    cursor: pointer;
}

.dropDownEllipsisSection {
    position:absolute;
    right:0;
    top:-5px;
    width: 80px;
}

.dropDownEllipsisSection p {
    text-align: center;
}

.commentDropDownSection {
    position: absolute;
    right: 0;
    width: 70px;
    background: #0f264a;
}

.commentDropDownSection p {
    font-size: 10px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    transition:background-color 0.2s ease-in-out;
}

.commentDropDownSection p:hover {
    background: #071120;
}

.dropDownEllipsisSection p {
   background:#09172E;
   cursor:pointer;
   padding: 5px 20px;
   font-size: 10px;
   color:#fff;
   font-weight: 400;
   transition: background-color 0.3s ease-in-out;
}

.dropDownEllipsisSection p:hover {
    background: #071120;
}

.deletePrompt span {
    position:absolute;
    cursor:pointer;
    color:#fff;
    font-size: 20px;
    top:30px;
    right:30px;
    z-index: 9999;
}

.deletePromptMainContainer {
    width: 340px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.deletePrompt h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;
    display: block;
}

.deletePrompt button {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 700;
    background: #FECF0C;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding: 10px;
}

.levelSpanSection {
    font-weight: 400;
    color: #ffd52d;
    font-size: 9px;
}

.editableMessageSection {
    resize: none;
    width: 100%;
    border-radius: 0px;
    border: 0px;
    background: none;
    color: #fff;
    padding-right: 5px;
    margin-top: 5px;
    height: 40px;
    font-size: 12px;
    padding: 0px 0px 5px 0px;
    font-weight: 200;
}

.onEditSectionBlk {
    background: #09172e;
    margin-top: 5px;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    padding: 15px;
    font-size: 10px;
}

.onEditFooterSectionBlk {
    border-top: 1px solid #2B315C;
    padding-top:10px;
    display: flex;
    justify-content: flex-end;
}

.onEditFooterSectionBlk {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    margin-top: 5px;
}

.onEditFooterSectionBlk button {
    background: none;
    border: none;
    padding: 5px 10px;
    font-size: 10px;
    color: #fff;
}

.onEditFooterSectionBlk button:last-child{
    background: #FECF0C;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.nameHoverCardBlk h3 {
    transition: color 0.2s ease-in-out
}

.nameHoverCardBlk{
    text-decoration: none;
}

.nameHoverCardBlk:hover h3  {
  color:#01C2F3;
}
