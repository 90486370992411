.createBadgeModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.createBadgePromptMainContainer {
    width: 500px;
    max-height:470px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
}

.scrollContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    height:420px;
}

.scrollContainer::-webkit-scrollbar {
    width: 10px;
}

.scrollContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}


.basicSettingsFieldSection::-webkit-scrollbar {
    width: 10px;
}

.basicSettingsFieldSection::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.basicSettingsFieldSection::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.closeIconCreateBadge {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 5px;
}

.flexBoxBlk {
    display:flex;
    position:relative;
    align-items: center;
}

.flexBoxBlk h1 {
    font-size: 20px;
    color:#fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.flexBoxBlk>div:last-child{
   margin-left: auto;
}

.createBadgeModal h3 {
    font-family: 'JosefinSans-Light', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color:#fff;
    display:block;
    margin-top: 15px;
}

.horizontalLine{
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.basicSettingsFlexBoxBlk {
    display:flex;
    gap: 20px;
    margin-top: 20px;
}

.basicSettingsFlexBoxBlk div{
    flex: 1;
    position: relative;
}

.basicSettingsFlexBoxBlk label {
   color: #fff;
   font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
   font-size: 13px;
   display:block;
   margin-bottom: 10px;
}

.basicSettingsFlexBoxBlk input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 8px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.basicSettingsFlexBoxBlk p {
    color:#7086a9;
    font-size: 10px;
    margin-top:5px;
}

.buttonCreateBadge{
    margin-top: 20px;
    display: block;
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;

}

.buttonCreateBadge i {
    color: #000;
    margin-left: 5px;
}

/* Image Block */
.uploadSectionFlexBox {
    display:flex;
    gap:10px;
}

.uploadSectionFlexBox div:first-child{
    flex:0;
}

.uploadSectionFlexBox div:last-child {
    flex:2;
}

.imageUploadSection .uploadBlurb {
    color:#fff;
    display:block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    text-decoration: underline;
    cursor: pointer;
}

.imageUploadSection .fileFormatBlurb{
    color: #fff;
    display: block;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #7086a9;
    font-size: 9px;
    margin-top: 0px;
}

.imageUploadSection .uploadedFileName {
    color: #fff;
    display: block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.imageUploadSection .badgeImageContainer {
    width:30px;
    display:block;
}

.imageUploadSection .closeIconBlock {
    cursor:pointer;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    background:#264e8f;
    right: -5px;
    top:-5px;
    z-index: 9999;
}

.closeIconBlock i {
    font-size: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
}

.hashTagNameFlex {
    margin-top: 15px;
    display:flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
}

.hashTagName {
    background: #040e2b;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff; 
    display:flex;
    gap:3px;
}

.hashTagName p {
    font-weight: 800;
    font-size: 12px;
}

.hashTagName>p:nth-child(2) {
    font-size: 10px;
    cursor: pointer;
    font-weight: 800;
    transition: color 0.3s ease-in-out;
}

.hashTagName>p:nth-child(2):hover {
    color:#FECF0C;
}