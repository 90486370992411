.input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    position: relative;
    padding-left: 45px;
}

.labelInputMobilePhone {
    position: relative;
}

.input::placeholder {
    color: #081429;
}

.label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.input:focus {
    outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}