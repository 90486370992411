.hashTageBlockSection {
    padding-bottom:10px;
}

.saturnImage {
    right: 0px;
    position:absolute;
    top: 20px;
}

.hashTagMainSectionBlock {
    padding: 0px 20px 15px 20px;
    position:relative;
    overflow:hidden;
}

.hashTagMainSectionBlock h1 {
    color: #fff;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 11px;
}

.hashTagListBlock {
    margin-top: 15px;
}

.hashTagListBlock h3 {
    color: #fff;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 11px;
    display:block;
    color:#01C2F3;
}

.hashTagListBlock h2 {
    font-size: 9px;
    display:block;
    margin-top: 3px;
    font-weight: 400;
    color:#426093;
}