.manageAddOnListBlk {
    width: 100%;
    border-radius: 8px;
    background: #0D1F3E;

}

.addOnListMainContainer {
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.addOnListMainContainer::-webkit-scrollbar {
    width: 10px;
}

.addOnListMainContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.addOnListMainContainer::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

/* Create HashTag Page */

.manageProductBlkTitle {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2B315C;
}

.manageProductBlkTitleButton {
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.manageProductBlkTitleButton i {
    color: #000;
    margin-right: 5px;
}

.manageProductBlkTitle h1 {
    font-size: 16px;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
}


.headerTableBlkHeaderAddOnList {
    margin-right: 10px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.headerTableBlkHeaderAddOnList p {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}


.rowBlkHashtaglist {
    border-top: 1px solid #2B315C;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.updateDropDownBlk {
    position: absolute;
    width: 70px;
    background: #0f264a;
    z-index: 9999;
}

.updateDropDownBlk p {
    font-size: 10px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
}

.updateDropDownBlk p:hover {
    background: #071120;
}


.updateFormBlkSection,
.edit-delete-btn-hashtaglist {
    display: flex;
    gap: 3px;
}

.updateFormBlkSection input {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    background: none;
    border: none;
    border-bottom: 1px solid #2B315C;
}

.updateFormBlkSection button {
    background: #050e2d;
    font-size: 10px;
    border: none;
    padding: 5px;
    color: #fff;
}

.updateFormBlkSection input:focus {
    outline: none;
}

.rowBlkHashtaglist .errorMessageUpdateHashtagList {
    font-size: 8px;
    display: block;
    margin-top: 5px;
    color: #ff0035;
}

.editDeleteBtnHashtaglist {
    display: flex;
    gap: 3px;
}

.notAvailableBlkTxt {
    color: #FECF0C;
    padding: 10px 20px;
}


/* Create Modal */

.addOnModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}


.createAddOnModalMainContent {
    width: 500px;
    max-height: 450px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
}

.closeIconCreateBadge {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 5px;
    background: none;
    border: none;
}

.flexBoxBlk {
    display: flex;
    position: relative;
    align-items: center;
}

.flexBoxBlk h1 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.formSection h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

h2 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.flexBoxBlk>div:last-child {
    margin-left: auto;
}

.horizontalLine {
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.formSection {
    margin-top: 0px;
}

.formSection .inputFlexBox {
    display: flex;
    gap: 20px;
}

.formSection .inputFlexBox div {
    flex: 1;
}

.formSection label {
    margin-top: 20px;
}

.buttoncreateAddOn {
    margin-top: 10px;
    display: block;
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.buttoncreateAddOn i {
    color: #000;
    margin-left: 5px;
}


.formSection .dropzone img {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.previewImageBlk span {
    position: absolute;
    right: 10px;
    background: red;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    z-index: 9999;
}

.formSection .dragBoxBlk {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    position: relative;
    border: 2px dashed rgb(13, 34, 66);
    cursor: pointer;
}

.previewImageBlk {
    width: 100%;
    position: relative;
}

.formSection .dragBoxBlk p {
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.addOnSection {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    margin: 20px 0px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.addOnSection .flexBoxBlk {
    align-items: center !important;
}

.addOnSection h1 {
    font-size: 12px;
    font-weight: 300;
}


.rowBlkAddOnList {
    border-top: 1px solid #2B315C;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.rowBlkAddOnListContainer>.rowBlkAddOnList:last-child {
    padding-bottom: 40px;
}

.rowBlkAddOnList p {
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.rowBlkAddOnList i {
    display: block;
    margin-left: 10px;
    cursor: pointer;
}

.rowBlkAddOnList img {
    width: 100px;
}

/* DropDown Edits */

.dropDownEdit {
    background: #132b55;
    position: absolute;
}

.dropDownEdit span {
    text-align: center;
    display: block;
    cursor: pointer;
    padding: 5px 20px;
    font-size: 10px;
    color: #fff;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
}

.dropDownEdit span:hover {
    background: #051021;
}

.dropDownEdit .fa-xmark {
    display: none;
}

/* Delete Modal */
.addOnDeletetModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}


.deletePromptMainContainer {
    width: 340px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.deletePrompt h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;
    display: block;
}

.deletePrompt button {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 700;
    background: #FECF0C;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding: 10px;
}

.deletePrompt button:focus {
    outline: none;
}

.deleteIcon {
    width: 40px;
    margin: 0 auto 10px auto;
    display: block;
}

.deletePromptMainContainer span {
    position: absolute;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    top: 20px;
    right: 30px;
}

.dropDownFlexContent label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.dropDownFlexContent {
    align-self: flex-end;
}


.cellCollapsibleSection {
  position:relative;
  margin-right: 10px;
}

.cellCollapsibleSection p {
    padding-right: 10px;
}

.cellCollapsibleSection i {
    background:#09172E;
    padding: 10px;
    font-size: 8px;
    right: -10px;
    position: absolute;
    top: 0;
}