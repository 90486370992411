.notificationMainContainer {
    position:relative;
 
}

.notificationContainer i {
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    transition:color 0.3s ease-in-out;
}

.notificationContainer i:hover{
    color: #01C2F3;
}

.notificationListContainer {
    background: #040b1f;
    border: 1px solid #191e38;
    right: 0;
    top: 30px;
    position: absolute;
    padding: 20px 15px;
    width: 300px;
    border-radius: 5px;
}

.listContent {
    border-top: 1px solid #191e38;
    padding: 10px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease-in-out;
}
.notificationListScrollBlock {
    max-height: 200px;
    overflow-y: scroll;
}


.notificationListScrollBlock::-webkit-scrollbar {
    width: 10px;
}

.notificationListScrollBlock::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.notificationListScrollBlock::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.listContent:hover {
    background:#0D1F3E;
}

.listContent:first-child {
    border-top: none;
}

.listContent p {
    font-size: 11px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-weight: 200;
}

.noNotificationContainer p {
    text-align: center;
    font-size: 12px;
    color:#9b9b9b;
    font-weight: 200;
}

.notificationSpanCounter {
    background: #fa2727;
    position: absolute;
    width: 15px;
    height: 15px;
    top:-5px;
    right: -8px;
    border-radius: 50%;
    color:#fff;
    font-weight: 800;
    text-align: center;
}

.notificationSpanCounter span {
   position:absolute;
   font-size: 7px;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.headerNotificationText {
    font-size: 18px;
    color:#FECF0C;
    display:block;
    margin-bottom:5px;
    padding-bottom: 5px;
}