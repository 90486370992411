.recipientButtonContainer {
    position: relative;
}

.recipientButton  {
    background: #0D1F3E;
    border: 1px solid #2B315C;
    padding: 10px 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 600;
}

.mainContainer {
    max-height: 250px;
    overflow-y: scroll;
}


.mainContainer::-webkit-scrollbar {
    width: 7px;
}

.mainContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.mainContainer::-webkit-scrollbar-thumb {
    background: rgb(39, 108, 226);
    border-radius: 10px;
    cursor: pointer;
}

.modalContainer {
    position:absolute;
    top: 45px;
    width: 380px;
    padding: 15px;
    border-radius: 10px;
    background:#143262;
    z-index: 1;
    box-shadow: 0px 0px 5px #0f1c32;
}


@media screen and (max-width:1300px) {
    .modalContainer {
        width:300px;
    }
}

.paragraphSelectedBlk {
    color: #fff;
    display:block;
    text-align: center;
}

.searchInputFilter {
    width: 97%;
    padding: 7px;
    margin-bottom: 8px;
    padding-right: 10px;
    border:none;
    font-size: 13px;
    border-radius: 4px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.searchInputFilter:focus {
    outline:none;
}

.listBlockContainer {
    display:flex;
    gap: 10px;
    border-bottom: 1px solid #032a53;
    padding-top: 10px;
    padding-bottom:10px;
    cursor: pointer;
    padding-left: 10px;
    margin-right: 10px;
    transition: background-color 0.2s ease-in-out;
    border-radius: 5px;
}

.listBlockContainer img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.listBlockContainer:hover {
    background: rgba(9, 23, 46, 1);
    border-radius: 5px;
}

.listBlockContainer:last-child {
    margin-bottom: 0px;
    border-bottom: none;
}

.listBlockContainer h1{
   text-transform: capitalize;
   font-size: 14px;
   font-weight: 400;
   color: #fff;
   font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.avatarRecipientList {
    object-fit: cover;
}

.listBlockContainer p {
    font-size: 10px;
    font-weight: 400;
    color:#818181;
}
