.input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    position:relative;
    padding-left: 45px;
}

.labelInputMobilePhone {
    position:relative;
}

.testing {
    height: 100%;
    display:flex;
    z-index: 1;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background:#143974;
    width:40px;
    padding-left:8px;
    font-weight: 600;
    color:#fff;
    font-size: 12px;
    position:absolute;
    justify-content: center;
    flex-direction: column;
}

.input::placeholder {
    color: #081429;
}

.label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.input:focus {
    outline: none;
}