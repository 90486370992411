.modalContainer {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

}
.modalContainer p {
    font-weight: 200;
}

.modalMainBlk {
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    padding: 10px 30px;
    width: 500px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-height: 380px;
    overflow-y:auto;
}

.modalMainBlk::-webkit-scrollbar {
    width: 10px;
}

.modalMainBlk::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
    
}

.modalMainBlk::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.disabled {
    color: #212e44;
}

.closeNotificationModalIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}


/* PostCard Styles */

.headerSectionStyles {
    display: flex;
    align-items: center;
    padding: 25px 0px 10px 0px;
}

.avatarSection {
    margin-right: 7px;
}

.avatarSection img {
    width: 23px;
    height: 23px;
    object-fit: cover;
    border-radius: 50%;
}

.headerSectionStyles span,
i {
    color: #fff;
    display: block;
}

.pronounSection span {
    margin-left: 7px;
    color: #426093;
    font-size: 9px;
    align-self: center;
}

.dateSection span {
    color: #586164;
    font-size: 11px;
}

.pointsGivenPill {
    margin-right: 7px;
    background: #01C2F3;
    padding: 5px 15px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 60px;
}


.headerSectionStyles h3 {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}


.preferredNameSection {
    text-transform: capitalize;
}

.preferredNameSectionFlexBox {
    display: flex;
    align-items: center;
}

.dropDownContainer {
    position: relative;
    margin-left: 10px;
    z-index: 3;
}

.dropDownBlk {
    position: absolute;
    top: 5px;
    right: 0px
}

.dropDownBlk p {
    font-size: 10px;
    text-align: center;
}

.editButton,
.ellipsisBlk,
.deleteButton {
    cursor: pointer;
}

.editButton,
.deleteButton {
    background: #0D1F3E;
    transition: background-color 0.2s ease-in-out;
    padding: 8px 20px;
}

.deleteButton:hover,
.editButton:hover {
    background: #051021;
}

.avatar {
    width: 23px;
    height: 23px;
    display: block;
    border-radius: 50%;
}

.recipientListBlk {
    color: #FECF0C;
    margin-left: 3px;
    display:inline-block;
    text-transform: capitalize;
}

.uploadedImage {
    width: 100%;
    margin-top: 15px;
    border-radius: 7px;
}

.footerSectionStyles {
    display: flex;
    padding: 15px 0px 0px 0px;
}

.footerSectionStyles>div:last-child {
    margin-left: auto;
}

.likeContainer {
    color: #fff;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-right: auto;
    width: 250px;
    margin-top: 10px;
}

.likeContainerFooterHR {
    width: 100%;
    height: 0.5px;
    border: 0px;
    display: block;
    margin: 15px 0px;
    background: #2B315C;
}

.likeContainer p {
    font-size: 12px;
    color: #01C2F3;
}

.likeContainer i {
    font-size: 12px;
    color: #01C2F3;
}

.likeContainer span {
    font-size: 12px;
    color: #01C2F3;
}

.showAllCommentsSection {
    display: flex;
    justify-content: flex-end;
}

.showAllCommentsSection button {
    color: #01C2F3;
    font-size: 12px;
    background: none;
    border: none;
}

.hrStyles {
    width: 100%;
    height: 0.5px;
    border: 0px;
    display: block;
    margin: 15px 0px;
    background: #2B315C;
}

.spanHashTagSection {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    display:inline-block;
}

.levelSectionCard p {
    font-weight: 400;
    color: #ffd52d;
    font-size: 9px;
}


.badgeSection {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0px;
}

.badgeSectionParagraphMessage {
    white-space: normal;
        word-wrap: break-word;
        word-break: break-word;
}

.badgeSection div:last-child {
    margin-left: auto;
}

.badgeSection h1 {
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
}


.postShownSectionBlk .badgeSection img {
    width: 30px;
}

.multipleImageSelection {
    display: flex;
}

.multipleImageSelection img {
    width: 35px;
    height: 35px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50%;
    object-fit: cover;
    background: #09172E;
}

.multipleImageSelection img:first-child {
    margin-right: -15px;
    z-index: 2;
}

.multipleImageSelection img:nth-child(2) {
    margin-right: -15px;
    z-index: 1;
}


.scrollSectionpostShownBlk {
    min-height: 30px;
    max-height: 135px;
    height: auto;
    overflow-y: scroll;
}

.scrollCommentListSection{
    overflow-y: scroll;
    max-height: 100px;
}

.scrollCommentListSection::-webkit-scrollbar {
    width: 10px;
}

.scrollSectionpostShownBlk::-webkit-scrollbar {
    width: 10px;
}

.scrollCommentListSection::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.scrollSectionpostShownBlk::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.scrollCommentListSection::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.scrollSectionpostShownBlk::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.postShownSectionBlk .uploadedImageSectionBlk {
    width: 100px;
}

.postShownSectionBlk .multiImageShow {
    width: 30px;
    height: 30px ;
}

.messageBlockBadgeSection {
    max-height: 80px;
    overflow-y: scroll;
}

.messageBlockBadgeSection::-webkit-scrollbar {
    width: 10px;
}

.messageBlockBadgeSection::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.messageBlockBadgeSection::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}


@media screen and (max-width:900px) {
    .modalMainBlk {
        width: 90%;
    }
}