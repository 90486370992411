.levelContainer {
    border-radius: 15px;
    position:relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.flexBox {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.flexBox>div:last-child {
    flex: 1;
}

.planetLevelImageBg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 1;
    object-fit: cover;
}

.planetLevelIBg {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.levelContainer h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #fff;
    position: relative;
    z-index: 1;
    padding: 10px 20px 15px 0px;
}

.levelContainer h2 {
    color: #fff;
    font-size: 13px;
    display:block;
    margin-bottom: 5px;
}

.levelContainer p {
    color: #fff;
    font-size: 12px;
    font-weight:400;
}

.levelDescriptionBlk {
    position: relative;
    padding: 20px;
}


.specialAwardSectionBlk {
    border-radius: 15px;
    background:#0D1F3E;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.specialAwardDescriptionSectionBlk h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #fff;
    z-index: 1;
    position: relative;
    padding-top:5px;
}

.specialAwardDescriptionSectionBlk {
    padding: 20px;
}

.specialAwardDescriptionSectionBlk p {
    font-size: 12px;
    position:relative;
    display:block;
    font-weight: 400;
    margin-top: 10px;
}

.specialAwardDescriptionSectionBlk button {
   width: 100%;
   color:#000;
   position:relative;
   font-weight: 600;
   background:#FECF0C;
   padding: 10px;
   border-radius: 30px;
   border:none;
   margin-top: 10px;
   display:block;
}

.cometImage {
    width: 70px;
    top:20px;
    right:0;
    position: absolute;
}