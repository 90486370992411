.flexBoxBlk  {
    gap: 30px;
    justify-content: center;
    align-items: baseline;
}

.flexBoxBlk a {
    font-size: 11px;
    color:#fff;
    font-weight: 600;
    text-decoration: none;
    transition:color 0.3s ease-in-out;
}

.flexBoxBlk a:hover {
   color: #01C2F3;
}

.flexBoxBlk i {
    margin-right: 7px;
    font-size: 13px;
    vertical-align: middle;
    transition: color 0.3s ease-in-out;
}

.flexBoxBlk img {
    vertical-align: middle;
    width: 20px;
    display: inline-block;
    margin-right: 8px;   
    transition: all 0.2s ease-in-out;
}

.flexBoxBlk a:hover img {
    filter :invert(71%) sepia(49) saturate(7754%) hue-rotate(-145deg) brightness(211%) contrast(118%);
}

.flexBoxBlk a:hover i {
    color:#01C2F3;
}
