.updatePostModalContainer {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
}

.updatePostModalMain {
    width: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 10%;
}

@media screen and (max-width:700px) {
    .updatePostModalMain {
        width: 92%;
    }
}

.updatePostModalMain i {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}

.updatePostModalMain img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.updatePostModalMain h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #fff;
    font-size: 20px;
    border-bottom: 1px solid #2B315C;
    padding-bottom: 15px;
}

.updatePostModalMain hr {
    width: 100%;
    height: 0.5px;
    border: 0px;
    display: block;
    margin: 5px 0px;
    background: #2B315C;
}

.updatePostModalMain button {
    width: 120px;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    padding: 10px;
    border-radius: 30px;
    border: none;
    margin-top: 10px;
    display: block;
    margin-left: auto;
}

.imageSectionEditPost {
    margin-top: 5px;
    margin-bottom: 10px;
}

.textAreaSectionBlk {
    height: 120px;
}

.imageSectionEditPost img {
    width:100px;
    border-radius: 0px;
}

.imageSectionEditPostFlexBox {
    display:flex;
    gap: 20px;
}

.imageSectionEditPostFlexBox div:last-child {
    flex: 1;
}

.footerBlkUpdateSection {
    display: flex;
    align-items: center;
}
.footerBlkUpdateSection div:last-child{
    flex: 1;
}

.footerBlkUpdateSectionIcons {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #2B315C;
    padding: 5px 20px;
    border-radius: 5px;
}

.footerBlkUpdateSectionIcons span {
    color: #fff;
    font-size: 10px;
    padding: 5px;
    cursor:pointer;
}

.updatePostModalContainer .removeImageBlkSectionButton {
    background:none;
}

.imageButtonContainer {
    display:flex;
    gap:5px;
    align-items: center;
    cursor:pointer;
}

.imageButtonContainer p {
    font-size: 11px;
}