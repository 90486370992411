.customSelect {
    position: relative;
    width: 100%;
    cursor: pointer;
    margin-top: 10px;
}

.selectDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
}

.optionList {
    position: absolute;
    border-radius: 3px;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    list-style: none;
    border: none;
    background-color: #073461;
    max-height: 100px;
    overflow-y: auto;
    z-index: 1000;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}


.optionList::-webkit-scrollbar {
    width: 10px;
}

.optionList::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.optionList::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.optionItem {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.optionItem:hover {
    background-color: #131c33;
}

.icon {
    right: 10px;
    top: 13px;
    position: absolute;
}


.selectDisplay span {
    color: #677da2;
}


