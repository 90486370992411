.productCardContainer {
    padding: 15px !important;
    border-radius: 10px;
    background:#09172E;
    border: 1px solid #2B315C;
    cursor:pointer;
}

.productCardContainer img {
    display:block;
    width: 100%;
    margin-bottom: 15px;
    object-fit: cover;
    border-radius: 8px;
    height :180px;
}

.flexBoxBlk {
    padding: 0px !important;
    display:flex;
    align-items: center;
}

.flexBoxBlk h2 {
    flex: 2;
}
    
.flexBoxBlk p {
    flex: 1;
    border: 1px solid #FECF0C;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
    color: #FECF0C;
    font-size: 12px;
}

.productCardContainer h2 {
    font-size: 15px;
    text-align: left;
}