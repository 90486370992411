.manageHashTagListBlk{
    width: 100%;
    border-radius: 8px;
    background: #0D1F3E;
}


.hashTagListMainContainer {
    max-height: 400px;
    overflow-y: scroll;
  
}

.hashTagListMainContainer::-webkit-scrollbar {
    width: 10px;
}

.hashTagListMainContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.hashTagListMainContainer::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

/* Create HashTag Page */

.manageHashtagBlkTitle {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2B315C;
}

.manageHashtagBlkTitleButton {
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.manageHashtagBlkTitleButton i {
    color: #000;
    margin-right: 5px;
}

.manageHashtagBlkTitle h1 {
    font-size: 16px;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
}


.headerTableBlkHeaderHashtaglist {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.headerTableBlkHeaderHashtaglist p {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}


.rowBlkHashtaglist {
    border-top: 1px solid #2B315C;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.rowBlkHashtaglistContainer>.rowBlkHashtaglist:last-child {
    padding-bottom:40px;
}

.rowBlkHashtaglist p {
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.rowBlkHashtaglist i {
    display:block;
    margin-left: 10px;
    cursor:pointer;
}

.updateDropDownBlk {
    position: absolute;
    width: 70px;
    background: #0f264a;
    z-index: 9999;
}

.updateDropDownBlk p {
    font-size: 10px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
}

.updateDropDownBlk p:hover {
    background: #071120;
}


.updateFormBlkSection,
.edit-delete-btn-hashtaglist {
    display: flex;
    gap: 3px;
}

.updateFormBlkSection input {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    background: none;
    border: none;
    border-bottom: 1px solid #2B315C;
}

.updateFormBlkSection button {
    background: #050e2d;
    font-size: 10px;
    border: none;
    padding: 5px;
    color: #fff;
}

.updateFormBlkSection input:focus {
    outline: none;
}

.rowBlkHashtaglist .errorMessageUpdateHashtagList {
    font-size: 8px ;
    display: block;
    margin-top: 5px;
    color:#ff0035;
}

.editDeleteBtnHashtaglist {
    display: flex;
    gap: 3px;
}

.notAvailableBlkTxt {
    color: #FECF0C;
    padding: 10px 20px;
}
