.toggleContainer {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

.on {
    background-color: #FECF0C;
}

.toggleCircle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
    position: absolute;
    left: 3px;
}

.on .toggleCircle {
    transform: translateX(24px);
}