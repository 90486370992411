.mainContainer {
    background:#0D1F3E;
    padding: 22px 30px;
    border-radius: 15px;
}

textarea {
    resize: none;
    width: 100%;
    border-radius: 0px;
    height: 70px;
    border:0px;
    background:none;
    color:#fff;
    padding-right:5px;
    margin-top:15px;
}

.selectedPillContainer {
    display:flex;
    flex-wrap: wrap;
    gap:5px;
    align-items: center;
}

.pointsParagraph {
    font-size: 13px;
    color:#fff;
}

.pointsParagraph span {
    color: #ffd52d;
    font-weight: 800;
}

.flexBoxBlk p {
    font-size: 12px;
    color:#FECF0C;
}


textarea::-webkit-scrollbar {
    width: 10px;
}

textarea::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;

}

textarea::-webkit-scrollbar-thumb {
    background: rgb(18, 50, 101);
    border-radius: 10px;
    cursor: pointer;
}

textarea::placeholder {
    color: #475467;
}

textarea:focus{
    outline: none;
    color:#fff;
}

.headerBlock, .footerBlock {
    display:flex;
    gap:10px;
}

.headerBlock {
    margin-bottom: 10px;
}

.imagePreviewContainer {
    position:relative;
    margin-top: 25px;
}

.imagePreviewContainer i {
    color:#fff;
    position:absolute;
    top: 50%;
    left:50%;
    transform:translate(-50%,-50%);
       
}

.closeImagePreview {
    position:absolute;
    top:10px;
    right:10px;
    background: #0f377a;
    border-radius: 50%;
    height:30px;
    width: 30px;
    cursor:pointer;
    box-shadow: 0px 0px 2px #1e1e1e;
}

.imagePreview {
    width: 100%;
    border-radius: 10px;
}

.footerBlock {
    margin-top: 10px;
}

.footerBlock>div:last-child {
    margin-left: auto;
}

.postButton {
    background:#FECF0C;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 600;
    color:#000;
    border:0px;
    display:flex;
    align-items: center;
    transition:background-color 0.3s ease-in-out
}

.postButton:focus {
    outline:none;
}

.postButton:hover {
    background: #ffd52d;
}

.postButton i {
    font-size: 16px;
    margin-left:5px;
    color:#000;
}

.selectedPillContainer {
   display:flex;
   gap: 5px;
}

.selectedPillTabBlk {
    background: #040e2b;
    display: flex;
    align-items: center;
    gap: 7px;
    border-radius: 5px;
    padding: 5px 10px;
}

.selectedPillTabBlk p {
    text-transform: capitalize;
}

.selectedPillTabBlk p {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}

.selectedPillTabBlk span {
    color: #fff;
    font-size: 10px;
    font-weight: 800;
    cursor: pointer;
    border-left: 1px solid #152a4d;
    padding-left: 5px;
    transition:color 0.3s ease-in-out;
}

.selectedPillTabBlk span:hover {
    color: #ffd52d;
}

.flexBoxBlk p:last-child::after {
    content: 
    "";
}

.flexBoxSectionhashTagSectionPost {
    display:flex;
    flex-wrap: wrap;
    gap:5px;
    margin-bottom: 10px;
}

.hashTagSectionPostH1 {
    background: #040e2b;
    display: inline-block;
    align-items: center;
    gap: 7px;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color:#fff;
}

.hashTagSectionPostH1 span {
    cursor:pointer;
    transition: color 0.2s ease-in-out;
}

.hashTagSectionPostH1 span:hover {
    color:#FECF0C;
}