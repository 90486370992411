.myProfileContainerSection {
    display:flex;
    gap: 20px;
    margin-top: 10px;
}

.myProfileContainerSection>div:first-child {
    width: 300px;
}

.myProfileContainerSection>div:last-child {
    background: none;
    flex: 1;
}

.personalDetailsSectionBlk {
    background:#0D1F3E;
    padding: 15px;
    align-items: center;
    border-radius: 15px;
}

.headerPersonalDetailsSectionBlk {
    display:flex;
}

.headerPersonalDetailsSectionBlk>div:last-child {
    margin-left:auto;
}

.personalDetailsSectionBlk hr {
    background: #2B315C;
    height: 1px;
    border: 0px;
}

.personalDetailsBioSection {
    margin-top: 7px;
    white-space: normal;
    overflow-wrap: break-word ;
}

.personalDetailsBioSection p {
    font-size: 12px;
}

.personalDetailsContactSection p {
    font-size: 12px;
    display:block;
    margin-bottom: 7px;
}

.jobTitleSectionBlk {
    display:inline-block;
    color: #7FC97F;
    padding: 5px 15px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid #7FC97F;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.nameSectionBlk {
    display:flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
}

.nameSectionBlk>div:first-child {
   max-width: 190px;
}

.nameSectionBlk>div:last-child{
    flex: 1;
}

.nameSectionBlk h1 {
    font-weight: 400;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 29px;
    color:#fff;
}

.nameSectionBlk h3 {
    font-size: 12px;
    font-weight: 400;
    color:#8198BF;
}

.avatarImageProfileSection {
    background: rgb(220, 220, 220);
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 5px solid #fff;
    border-radius: 50%;
}


.editProfileButtonSection {
    background:#01C2F3;
    padding: 10px 15px;
    display:block;
    font-weight: 600;
    color: #000;
    text-decoration: none;
    border-radius: 50px;
    font-size: 12px;
}

.scrollDivBadgesSectionProfile {
    display:block;
}

.scrollDivBadgesSectionProfile h1 {
    font-size: 18px;
    color: #fff;
    display:block;
    margin-bottom: 15px;
    margin-top: 20px;
    font-weight: 400;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
}

.scrollDivBadgesSectionProfile img {
    width: 23px;
} 

.scrollDivBadgesSectionProfile h2 {
    font-size: 12px;
    color:#fff;
}

.badgeListProfile {
    margin-top: 15px;
    display:flex;
    gap: 10px;
    align-items: center;
}


@media screen and (max-width:998px) {
    .myProfileContainerSection>div:last-child {
        margin-top: 20px;
    }
}

@media screen and (max-width:920px) {
    .myProfileContainerSection {
        display:block;
    }

    .myProfileContainerSection>div:first-child{
        width: 100%;
    }
}
