.hrSectionBlk {
    width: 100%;
    height: 0.5px;
    border: 0px;
    display: block;
    margin: 10px 0px 20px 0px;
    background: #2B315C;
}

.profileTabContainer {
    width: 200px;
    display: flex;
    gap: 20px;
}

.profileTabContainer {
    color: #fff;
    font-size: 12px;
}

.tabProfile {
    cursor: pointer;
}
.activeTab {
    color: #01C2F3;
}
