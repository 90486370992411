.progressBarContainer {
    margin-top: 10px;
    display:flex;
    gap: 2px;
    align-items: center;
}

.progressBarContainer>div:first-child{
    min-width:130px;
    background:rgba(9,23,46, 0.8);
    height:8px;
    border-radius: 20px;
}

.progressBarContainer>div:first-child div {
   background:#01C2F3;
   border-radius: 20px;
   height:100%;
}

.progressBarContainer>span {
    margin-right:auto;
    color: #fff;
    font-size: 12px;
    text-align: left;
    margin-left:5px;
}

