.amountButton {
    background: #0D1F3E;
    border: 1px solid #2B315C;
    padding: 10px 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 600;
}

.amountButtonContainer {
    position: relative;
}

.modalContainer {
    position: absolute;
    top: 45px;
    padding: 15px;
    border-radius: 10px;
    background: #143262;
    z-index: 1;
    width: 350px;
    box-shadow: 0px 0px 5px #0f1c32;
}

@media screen and (max-width:1200px) {
    .modalContainer {
       margin-left:-110px;
       width:300px;
    }
}

.flexBoxBlk {
   display:flex;
   justify-content: center;
   gap: 5px;
}

.flexBoxBlk span {
    background:#0e1d49;
    padding: 10px 20px;
    color:#fff;
    border-radius: 20px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition:background-color 0.2s ease-in-out;
}

@media screen and (max-width:1200px) {
    .flexBoxBlk span {
        padding: 10px 15px;
    }
}

.flexBoxBlk span:hover {
    background: rgba(9, 23, 46, 1);
}

.modalContainer p {
    text-align: center;
    display: block;
    margin-top: 10px;
    font-size: 11px;
    font-weight: 500;
}

.inputAmount {
    background: #0e1d49;
    width: 100%;
    display: block;
    padding: 8px;
    border: none;
    border-top-left-radius : 7px;
    border-bottom-left-radius : 7px;
    color: #fff;

}

.inputAmount:focus {
    outline: none;
}

.flexBoxBlkAmount {
    margin-top: 7px;
    display:flex;
}

.flexBoxBlkAmount button {
   background:#FECF0C;
   border:none;
   font-size: 10px;
   display:block;
   font-weight: 600;
   width:50px;
   border-top-right-radius: 7px;
   border-bottom-right-radius: 7px;
   color:#000;
}
