.dropdownMenu {
    position: absolute;
    margin-top:-5px;
    width: calc(100% - 2rem);
    overflow: hidden;
    z-index: 10;
    border: 1px solid #2B315C;
    border-top:none;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background: #0D1F3E;
    font-size: 12px;
    width: 100%;
    font-weight: 300;
    width: 120px;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    margin-left: -1px;
}

.orderStatusContainer {
    font-size: 12px;
    font-weight: 300;
    background: #0D1F3E;
    border: 1px solid #2B315C;
    border-radius: 8px;
    position: relative;
    color:#fff;
    width:120px;
}

.statusItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    margin-bottom: 4px;
}

.statusItem:last-child {
    margin-bottom: 0;
}

.statusIcon {
    color: white;
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.statusPurchased {color:#01C2F3;}
.statusPending {color:#FECF0C;}
.statusProcessed {color:#7FC97F;}