.blockContainer {
    background:#0D1F3E;
    border-radius: 15px;
    margin-bottom:15px;
    position:relative;
}

.flexBox {
    display:flex;
    padding: 25px 20px 25px 20px;
    align-items: center;
    
}

.dots  {
    margin-left:auto;
    color:#fff;
}

.viewAllLink {
    margin-left: auto;
    color: #FECF0C;
    font-size: 12px;
    text-decoration: none;
}

.flexBox h3 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color : #fff;
}