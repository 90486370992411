.modalContainer {
    top: 0;
    position:fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.modalMainBlk {
    width: 340px;
    padding: 30px 20px;
    background:#09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position:absolute;
    transform:translate(-50%,-50%);
    left:50%;
    top:50%;
}

.disabled {
    color: #212e44;
}

.modalMainBlk img {
    width:40px;
    margin:0 auto 10px auto;
    display:block;
}

.modalMainBlk i {
    position:absolute;
    right: 20px;
    top:20px;
    font-size: 20px;
    cursor:pointer;
}

.modalMainBlk h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;
    width: 200px;
    display: block;
}

.modalMainBlk button {
   font-family: 'Inter', Arial, Helvetica, sans-serif;
   font-weight: 700;
   background:#FECF0C;
   border-radius: 20px;
   border:none;
   width: 100%;
   padding: 10px;
   margin-top:10px;
}

@media screen and (max-width:900px) {
    .modalMainBlk {
        width: 80%;
    }
}