.createHashTagModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.createHashTagPromptMainContainer {
    width: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.createHashTagPromptMainContainer span {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 5px;
}

.flexBoxBlk {
    display: flex;
    position: relative;
    align-items: center;
}

.flexBoxBlk h1 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.flexBoxBlk>div:last-child {
    margin-left: auto;
}

.createHashTagModal h3 {
    font-family: 'JosefinSans-Light', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #fff;
    display: block;
    margin-top: 15px;
}

.horizontalLine {
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.basicSettingsFlexBoxBlk {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.basicSettingsFlexBoxBlk div {
    flex: 1;
    position: relative;
}

.basicSettingsFlexBoxBlk label {
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
}

.basicSettingsFlexBoxBlk input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 8px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.basicSettingsFlexBoxBlk p {
    color: #7086a9;
    font-size: 10px;
    margin-top: 5px;
}

.buttonCreateHashTag {
    margin-top: 10px;
    display: block;
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;

}

.buttonCreateHashTag i {
    color: #000;
    margin-left: 5px;
}

/* Image Block */
.uploadSectionFlexBox {
    display: flex;
    gap: 10px;
}

.uploadSectionFlexBox div:first-child {
    flex: 0;
}

.uploadSectionFlexBox div:last-child {
    flex: 2;
}

.imageUploadSection .uploadBlurb {
    color: #fff;
    display: block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    text-decoration: underline;
    cursor: pointer;
}

.imageUploadSection .fileFormatBlurb {
    color: #fff;
    display: block;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #7086a9;
    font-size: 9px;
    margin-top: 0px;
}

.imageUploadSection .uploadedFileName {
    color: #fff;
    display: block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.imageUploadSection .HashTagImageContainer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.imageUploadSection .closeIconBlock {
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    background: #264e8f;
    right: -5px;
    top: -5px;
    z-index: 9999;
}

.closeIconBlock i {
    font-size: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

