.mainContainerBlk {
    background: #0D1F3E;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 20px;
    margin-top:15px;
}

.flexBoxBlk {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom:25px;
}

.flexBoxBlk div {
    flex: 1;
}

.flexBoxBlk label {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    display:block;
    font-size: 12px;
    color: #fff;
    margin-bottom: 5px;
}

.flexBoxBlk input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
}

.flexBoxBlk input:disabled {
    color:#393f6b;
}

.flexBoxBlk input:focus {
   outline:none;
}

.bioSectionBlk label {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    display: block;
    font-size: 12px;
    color: #fff;

}

.limitParagraph {
    display:block;
    margin-top: 10px;
    font-size: 13px;
    color: #ff6969;
}

.bioSectionBlk textarea {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    font-size: 13px;
    margin-top: 5px;
}


.updateButtonBlk {
    margin-top: 20px;
    background: #FECF0C;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border: 0px;
    display: block;
    margin-left:auto;
    width: 150px;
    transition: background-color 0.3s ease-in-out;
}

.flexBoxBlkSection {
    display:flex;
    gap:5px;
    justify-content: center;
    align-items: center;
}

.flexBoxBlkSection p{
    color: #000;
    font-weight: 600;
}

.flexBoxBlkSection i {
    color: #000;
}

