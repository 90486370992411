.badgeSelectionModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.badgeSelectionPromptMainContainer {
    width: 500px;
    max-height: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
}

.scrollContainer {
    max-height: 155px;
    overflow-y: scroll;
}

.scrollContainer::-webkit-scrollbar {
    width: 10px;
}

.scrollContainer::-webkit-scrollbar-track {
    background: #040b15;
    border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: #163d7d;
    border-radius: 10px;
    cursor: pointer;
}

.closeIconBadge {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 5px;
}

.flexBoxBlk {
    display: flex;
    position: relative;
    align-items: center;
}

.flexBoxBlk h1 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.horizontalLine {
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.headerImage {
    width: 100%;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.buttonNextBlk {
    display:flex;
    justify-content: end;
    margin-top: 10px;
    
}

.buttonNextBlk button {
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    display: block;
}

.buttonNextBlk i {
    color: #000;
    margin-left: 5px;
}

.badgeListFlexBox {
    display:flex;
    border-bottom: 1px solid #2B315C;
    gap: 10px;
    padding: 10px 5px;
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.badgeListFlexBox div:last-child {
   flex:1;
}

.badgeListFlexBox:hover{
    background:#082040;
}

.badgeListFlexBox:last-child {
    border-bottom:0px;
}

.badgeListFlexBox h1 {
    color: #fff;
    font-size: 13px;
}

.badgeListFlexBox p {
    color:#567ab8;
    font-size: 11px;
}

.customRadionButton {
    border: 1px solid rgb(255, 255, 255);
    width: 12px;
    height: 12px;
    display:block;
    border-radius: 50%;
}

.selectedBadge {
    background:#082040;
}

.selectedBadgeRadio {
    background: #163d7d;
}

.titleSectionFlexBox {
    display:flex;
}

.titleSectionFlexBox span {
    display:block;
    background:#01C2F3;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 800;
    font-size: 10px;
    margin-left: auto;
}

.stepTwoContainerHeaderBlk {
    display:flex;
    gap: 10px;
    align-items: center;
}

.stepTwoContainerHeaderBlk i {
    cursor:pointer;
    display:block;
    align-self: center;
    font-size: 15px;
}

.titleSectionMainBlk {
    width:100%;
    display:flex; 
    align-items: center;
    padding:10px 0px;
}

.titleSectionMainBlk h1 {
    font-size: 18px;
    color: #fff;
}

.titleSectionMainBlk p {
    display: block;
    background: #01C2F3;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 800;
    font-size: 10px;
    margin-left: auto;
    color:#000
}

.scrollablleDivBlk {
    max-height: 200px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.mainSectionGiveBadge {
    height: 80px;
    overflow-y: scroll;
}


.mainSectionGiveBadge::-webkit-scrollbar {
    width: 10px;
}

.mainSectionGiveBadge::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.mainSectionGiveBadge::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.mainSectionGiveBadge p {
    font-size: 12px;
    color:#fff;
    display:block;
    margin-bottom: 10px;
}

.recipientBadgeButtonContainer {
   margin-bottom: 10px;
}

.recipientBadgeButton {
    background: #0D1F3E;
    border: 1px solid #2B315C;
    padding: 10px 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 600;
}

.givePointsButton {
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.givePointsButton button {
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    display: block;
}

.givePointsButton i {
    color: #000;
    margin-left: 5px;
}

.dropDownListUsersBlk {
    position:absolute;
    width: 270px;
    z-index: 99;
    border-radius: 10px;
    height: 170px;
    overflow-y: scroll;
    background:#143262;
    margin-top: 5px;
}

.dropDownListUsersBlk::-webkit-scrollbar {
    width: 10px;
}

.dropDownListUsersBlk::-webkit-scrollbar-track {
    background: #040b15;
    border-radius: 10px;
}

.dropDownListUsersBlk::-webkit-scrollbar-thumb {
    background: #163d7d;
    border-radius: 10px;
    cursor: pointer;
}

.badgeListContainerBlk {
    padding:10px;
    height: 100%;
}

.badgeListContainerBlk input {
    width: 100%;
    padding: 7px;
    margin-bottom: 8px;
    padding-right: 10px;
    border: none;
    font-size: 13px;
    border-radius: 4px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.badgeRowContainerBlk {
    display:flex;
    gap:10px;
    padding: 5px 10px;
    border-bottom: 1px solid #3a4281;
    cursor:pointer;
    transition: all  0.3s ease-in-out;
}

.badgeRowContainerBlk:hover {
    background:#040e2b;
    border-radius: 4px;
}

.badgeRowContainerBlk:last-child {
    border-bottom: none;
}

.badgeRowContainerBlk h2 {
    background: none;
    font-size: 14px;
    font-weight: 400;
    color:#fff;
}

.badgeRowContainerBlk p {
    background:none;
    font-size: 10px;
    margin:0px;
    padding:0px;
    font-weight: 400;
    color: #818181;
}

.badgeRowContainerBlk img {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.noUsersMessage {
    font-size: 10px;
    color: #fff;
    display: block;
    text-align: center;
}

.selectedUsersContainer {
    margin-top: 10px;
    display:flex;
    flex-wrap: wrap;
    gap: 5px;
}

.selectedUserItem {
    background: #040e2b;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    color:#fff;
}

.selectedUserItem span {
  font-size: 12px;
}

.selectedUserItem>span:last-child {
    cursor: pointer;
    padding-left: 3px;
    margin-left: 5px;
    font-size: 8px;
    border-left : 1px solid #152a4d;
}