.manageProductListBlk {
    width: 100%;
    border-radius: 8px;
    background: #0D1F3E;
}

.productListMainContainer{
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 30px;
}

.productListMainContainer::-webkit-scrollbar {
    width: 10px;
}

.productListMainContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.productListMainContainer::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

/* Create HashTag Page */

.manageProductBlkTitle {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2B315C;
}

.manageProductBlkTitleButton {
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.manageProductBlkTitleButton i {
    color: #000;
    margin-right: 5px;
}

.manageVariantButton {
    margin-left: auto;
    color: #FECF0C;
    position: relative;
    background:none;
    font-weight: 400;
    border-radius: 30px;
    border: 1px solid #FECF0C;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.manageVariantButton i {
    color: #FECF0C;
    margin-right: 5px;
    font-weight: 400;
}

.manageProductBlkTitle h1 {
    font-size: 16px;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
}


.headerTableBlkHeaderProductList {
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.headerTableBlkHeaderProductList p {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}


.rowBlkHashtaglist {
    border-top: 1px solid #2B315C;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.updateDropDownBlk {
    position: absolute;
    width: 70px;
    background: #0f264a;
    z-index: 9999;
}

.updateDropDownBlk p {
    font-size: 10px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
}

.updateDropDownBlk p:hover {
    background: #071120;
}


.updateFormBlkSection,
.edit-delete-btn-hashtaglist {
    display: flex;
    gap: 3px;
}

.updateFormBlkSection input {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    background: none;
    border: none;
    border-bottom: 1px solid #2B315C;
}

.updateFormBlkSection button {
    background: #050e2d;
    font-size: 10px;
    border: none;
    padding: 5px;
    color: #fff;
}

.updateFormBlkSection input:focus {
    outline: none;
}

.rowBlkHashtaglist .errorMessageUpdateHashtagList {
    font-size: 8px;
    display: block;
    margin-top: 5px;
    color: #ff0035;
}

.editDeleteBtnHashtaglist {
    display: flex;
    gap: 3px;
}

.notAvailableBlkTxt {
    color: #FECF0C;
    padding: 10px 20px;
}


/* Create Modal */

.createProductModal {
    top: 0;
    position: fixed;
    z-index: 99999999999999;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}


.createProductModalMainContent{
    width: 500px;
    max-height: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 48%;
}

.closeIconCreateBadge {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 5px;
    background:none;
    border:none;
}

.flexBoxBlk {
    display: flex;
    position: relative;
    align-items: center;
}

.flexBoxBlk h1 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.formSection h2 {
    color:#fff;
    font-size: 16px;
    font-weight: 400;
}

h2 {
    font-size: 20px;
        color: #fff;
        font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.flexBoxBlk>div:last-child {
    margin-left: auto;
}

.horizontalLine {
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.formSection {
    margin-top: 0px;
}

.formSection .inputFlexBox {
    display: flex;
    gap: 20px;
}

.formSection .inputFlexBox div{
   flex: 1;
}

.formSection label {
    margin-top: 20px;
}

.buttonCreateProduct {
    margin-top: 10px;
    display: block;
    margin-left: auto;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
}

.buttonCreateProduct i {
    color: #000;
    margin-left: 5px;
}

.scrollContainer {
    height: 400px;
    overflow-y: scroll;
}

.scrollContainer::-webkit-scrollbar {
    width: 10px;
}

.scrollContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}


.formSection .dropzone img {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.previewImageBlk span {
    position: absolute;
    right: 10px;
    background: red;
    width: 30px;
    height:30px;
    border-radius: 50%;
    color:#fff;
    z-index: 9999;
}

.formSection .dragBoxBlk {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    position: relative;
    border: 2px dashed rgb(13, 34, 66);
    cursor: pointer;
}

.previewImageBlk {
    width: 100%;
    position: relative;
}

.formSection .dragBoxBlk p {
    font-size: 12px;
    font-weight: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.addOnSection {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    margin: 20px 0px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.addOnSection .flexBoxBlk {
    align-items: center !important;
}

.addOnSection h1 {
   font-size: 12px;
   font-weight: 300;
}


.rowBlkProductList {
    border-top: 1px solid #2B315C;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    gap: 10px;
}

.rowBlkProductListContainer>.rowBlkProductList:last-child {
    padding-bottom: 40px;
}

.rowBlkProductList p {
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.rowBlkProductList i {
    display: block;
    margin-left: 10px;
    cursor: pointer;
}

.rowBlkProductList img {
    width:100px;
}

/* DropDown Edits */

.dropDownEdit {
    background: #132b55;
    position: absolute;
}

.dropDownEdit span {
    text-align: center;
    display: block;
    cursor: pointer;
    padding: 5px 20px;
    font-size: 10px;
    color: #fff;
    font-weight: 400;
    transition: background-color 0.3s ease-in-out;
}

.dropDownEdit span:hover {
    background: #051021;
}

.dropDownEdit .fa-xmark{
    display:none;
}

/* Delete Modal */
.deletePromptMainContainer {
    width: 340px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.deletePrompt h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;
    display: block;
}

.deletePrompt button {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 700;
    background: #FECF0C;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding: 10px;
}

.deletePrompt button:focus {
  outline:none;
}

.deleteIcon {
    width: 40px;
    margin: 0 auto 10px auto;
    display: block;
}

.deletePromptMainContainer span{
    position: absolute;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    top: 20px;
    right: 30px;
}

.dropDownFlexContent label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.dropDownFlexContent {
    align-self: flex-end;
}


/* Selected AddOns */

.addOnFlex {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
}

.addOn {
    background: #132F5D;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    display: flex;
    gap: 3px;
 
}

.addOn p {
    font-weight: 400;
    font-size: 12px;
}

.addOn>p:nth-child(2) {
    font-size: 10px;
    cursor: pointer;
    font-weight: 400;
    transition: color 0.3s ease-in-out;
}

.addOn>p:nth-child(2):hover {
    color: #FECF0C;
}

.variantContainer .headerInputField {
    display: block;
    margin-bottom: 10px;
}

.variantContainer h1 {
    display: block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 400;
    color: #fff;
}

.variantContainer h3  {
    display:block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.variantContainer div {
    background:#0d1f3e;
    flex:1;
    margin-bottom: 10px;
    border-radius: 10px;
}

.variantContainer input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.variantContainer input:focus {
    outline:none;
}

.addOptionButton {
    color : #FECF0C;
    font-weight: 400;
    border:none;
    background:none;
    display: block;
    margin-left: auto;

}

.variantRow {
    display:flex;
    align-items: center;
    padding: 20px;
    gap: 15px;
}

.variantContainer label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.removeOptionButton {
    background:none;
    border:none;
    color:#fff;
}


/* Merch Modal */



.merchContainer .headerInputField {
    display: block;
}

.merchContainer {
    margin-bottom: 0px;
}

.merchContainer input{
    margin-bottom: 0px !important;
}

.merchContainer h1 {
    display: block;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 400;
    color: #fff;
}

.merchContainer h3 {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.merchContainer div {
    background: #0d1f3e;
    flex: 1;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.merchContainer input {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
    margin-bottom: 20px;
}

.merchContainer input:focus {
    outline: none;
}

.merch .addOptionButton {
    color: #FECF0C;
    font-weight: 400;
    border: none;
    background: none;
    display: block;
    margin-top: 25px;
    margin-left: auto;
}

.merch .variantRow {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 15px;
}


.variantContainer label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.removeOptionButton {
    background: none;
    border: none;
    color: #fff;
}

.dropDownCreate {
    background:#FECF0C;
    width: 100%;
    left: 0;
    position: absolute;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}


.dropDownCreate span {
    display: block;
    cursor: pointer;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

.dropDownCreate span:hover {
   background:#ffdd57;
}

.dropDownCreate span:last-child{
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

.buttonActive {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius:18px;
    border-top-left-radius: 18px;
}


/* MERCH EDIT */
.merchEditBlock img  {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.merchEditBlock h2 {
    font-weight: 400;
    color: #fff;
    font-size: 16px;
}

.merchEditBlock .inputFlexBox {
    display:flex;
   
}

.merchBlock {
    position: relative;
}

.merchBlock .deleteVariantButton{
    position:absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background:#1a4ba0;
    border-radius: 50%;
    border:none;
 
}

.merchBlock i {color: #fff;}

.optionBlockFlexBox {
    display:flex;
    padding:0px !important;
    margin-top:20px;
    margin-bottom: 0px !important;
    gap: 10px;
}

.containerOptionBlk {
    padding: 0px !important;
  
}

.containerOptionBlk div:first-child {
    padding: 0px !important;
    flex: 2;
}

.containerOptionBlk div:last-child {
    flex: 0;
    padding: 0 !important;
}

.optionBlockFlexBox label {
    margin-top: 0px !important;
}

.optionBlockFlexBox button{
    width: 20px;
    height: 20px;
    background: #1a4ba0;
    border-radius: 50%;
    border: none;
    margin-top: 27px;
}