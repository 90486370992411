/* Shop Page */
.shopPageInnerBlk {
    display: flex;
    gap: 15px;
}

.shopPageLeftSectionBlk {
    display: block;
    width: 300px;
    position: sticky;
    top: 80px;
    height: 200px;
    overflow-y: auto;
}

.shopPageRightSectionBlk {
    flex: 3;
    margin-bottom: 80px;
}

.shopPageCoinsSectionBlk,
.shopPageCategoriesSectionBlk {
    background: #0D1F3E;
    padding: 20px;
    border-radius: 15px;
}

.shopPageCategoriesSectionBlk {
    margin-top: 10px;
}

.shopPageCoinsSectionBlk h2 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 14px;
}

.shopPageCoinsSectionBlk .flexBoxBlk {
    display: flex;
    margin-top: 20px;
    gap: 15px;
    justify-content: space-between;
    color: #fff;
}

.shopPageCoinsSectionBlk .flexBoxBlk img {
    width: 50px;
}

.shopPageCoinsSectionBlk .flexBoxBlk h1 {
    font-weight: 400;
}

.shopPageCoinsSectionBlk .flexBoxBlk span {
    display: block;
    text-align: right;
    font-size: 12px;
}

.shopPageRightSectionBlk h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 25px;
}

.containerProductListShopBlk {
    margin-top: 10px;
}

.containerBlkProductList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.containerBlkProductList div {
    background: #09172E;
    padding: 20px;
    text-align: center;
}

.containerBlkProductList div h2 {
    font-weight: 400;
}


@media screen and (max-width: 920px) {
    .shopPageInnerBlk {
        display:block;
    }
    .shopPageLeftSectionBlk {
        position: relative;
        width: 100%;
    }
    .shopPageRightSectionBlk {
        margin-top: 40px;
    }
}


@media screen and (max-width: 700px) {
    .containerBlkProductList {
        display: block;
    }
    .containerBlkProductList div {
        margin-bottom:30px;
    }
}