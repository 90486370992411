.modalDetailsProductContainer {
    position: fixed;
    z-index: 99999999999999;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
}

.modalMainContent {
    width: 500px;
    position: absolute;
    transform: translate(-50%, 3%);
    left: 50%;
}

.flexBoxBlk {
    display: flex;
    position: relative;
    align-items: center;
    padding: 30px 0px 0px 0px;
}

.flexBoxBlk button{
    border:none;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 15px;
    background: none;
}


.modalMainContent::-webkit-scrollbar {
    width: 10px;
}

.modalMainContent::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
    margin:10px;
}

.modalMainContent::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.descriptionBlk {
    padding: 15px 20px 0px 20px;
}

.descriptionBlk img {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 15px;
}

.spinnerBlkDiv {
    margin-top: 15px;
    margin-bottom: 15px;
}

.modalDetailsProductContainer h1 {
    font-size: 20px;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.modalDetailsProductContainer p {
    color: #fff;
}

.modalDetailsProductContainer hr {
    background: #2B315C;
    border: none;
    height: 1px;
    margin-top: 10px;
}

.merchDetailsBlk p {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.headerFlexBoxBlk {
    display: flex;
    align-items: center;
}

.headerFlexBoxBlk>div:last-child {
    margin-left: auto;
}

.headerFlexBoxBlk h1 {
    font-size: 30px;
    font-weight: 800;
}

.headerFlexBoxBlk h2  {
    font-weight: 400;
}


.voucherVariantList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;
}

.voucherVariantList div {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: center;
    border: 1px solid #2B315C;
    padding: 15px 10px;
    border-radius: 10px;
}

.voucherVariantList div h1 {
    font-size: 18px;
}

.voucherVariantList div p {
   font-size: 11px;
}

.claimButtonSection {
    background:#132F5D;
    padding: 20px;
    margin-top:20px;
    width: 100%;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.claimButtonSection button {
    margin: 0px auto;
    width: 200px;
    display:block;
    color: #000;
    position: relative;
    font-weight: 600;
    background: #FECF0C;
    border-radius: 30px;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 20px;
    margin-bottom: 10px;
}

.claimButtonSection i {
    color: #000;
}

.claimButtonSection p {
    color: #d6d6d6;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
}


.addOnsDetailsBlk {
    border: 1px solid #2B315C;
    border-radius: 5px;
}

.addOnListBlk {
   padding: 10px 0px;
}

.addOnCardList {
    display: flex;
    gap:10px;
    padding: 5px 10px;
    align-items: center;
}

.addOnCardList h3 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.addOnCardList div:last-child {
    margin-left: auto;
}

.addOnsDetailsBlk h2 {
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
   border-bottom: 1px solid #2B315C;
}

.contentBlk {
    background: #09172E;
    border: 1px solid #2B315C;
    margin-bottom:50px;
    border-radius: 30px;
}

.contentBlk>div:first-child {
   padding: 0px 20px 0px 20px;
}

.modalDetailsProductContainer .errorMessage {
    color: rgb(255, 51, 51);
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 13px;
}

.modalDetailsProductContainer .successMessage {
    color: #FECF0C;
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
}

.variantSectionBlk {
    display: block;
    margin-top: 30px;
}

.variantSectionBlk div {
    margin-top: 30px;
}

.variantSectionBlk h1 {
    font-size: 20px;
    font-weight: 400;
}

.variantSectionBlk .options {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 10px;
}

.variantSectionBlk button {
    font-weight: 100;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    background:none;
    color:#fff;
    text-align: center;
    border: 1px solid #2B315C;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
}

.variantSectionBlk .selectedVariant {
    background:#fff;
    color:#1D458C;
}

.productFormSection {
   margin-top: 20px;
   padding-bottom: 50px;
}
.productFormSection .flexBoxBlk {
   gap: 20px;
}

.productFormSection .flexBoxBlk div {
   flex: 1;
}

.productFormSection .flexBoxBlk div {
    flex: 1;
}

.productFormSection textarea {
    background: #0D1F3E;
    width: 100%;
    display: block;
    padding: 10px;
    border: 1px solid #2B315C;
    border-radius: 7px;
    color: #fff;
}

.footnote { display: flex; justify-content: flex-end;margin-top:10px;margin-bottom: 15px}
.footnote p {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #586164;
}

.productFormSection input:disabled {
    background-color: #0d1f3e;
    color: #999;
    border: 1px solid #222a50;
    cursor: not-allowed;
    opacity: 0.7;
}

.productFormSection label {
    font-family: 'JosefinSans-Regular';
    font-size: 12px;
    color: #fff;
    display: block;
    margin-bottom: 5px;
}

.orderButtonSectionConfirm {
    margin-top: 15px;
    display:flex;
    justify-content: flex-end;
}

.orderDetailsConfirmButton {
    background: #FECF0C;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border: 0px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease-in-out;
}

.orderDetailsConfirmButton i {
    color :#000;
    margin-left: 5px;
}

.errorText {
    color:rgb(255, 51, 51) !important;
}

.priceSection {
    border: 1px solid #FECF0C;
    text-align: center;
    border-radius: 10px;
    padding: 8px 10px 4px 10px;
    color: #FECF0C;
    font-size: 12px;
   
}

.priceSection h1 {
    font-weight: 600;
    font-size: 25px !important;
    color: #FECF0C;
}

@media screen and (max-width:768px) {
    .modalMainContent {
        width: 90%;
    }
}