.allowanceBlk, .giveablePointsBlk, .redeemablePointsBlk {
    display:flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    gap: 10px;
}


/* Allowance Block */
.allowanceBlk {
    background:#09172E;
    margin: 0px 20px 5px 20px;
    padding: 10px;
    border-radius: 5px;
}

.allowanceBlk p {
    font-size:11px;
}

.allowanceBlk span {
    color: #01C2F3;
    font-weight: 600;
}

.allowanceBlk>a{
   margin-left: auto;
   display: block;
   color: #fff;
}

/* Giveable Points */

.giveablePointsBlk {
    border-radius: 5px;
    padding: 10px 20px;
}

.giveablePointsBlk, p {
  font-size: 13px;
  font-weight: 600;
}


.giveablePointsBlk span {
    margin-left: auto;
    font-size: 13px;
    font-weight: 600;
}

.giveablePointsBlk p {
    font-size: 14px;
    font-weight: 600;
}

.giveablePointsBlk img {
    width: 20px;
}

/* Redeemable Points */

.redeemablePointsBlk {
    border-radius: 5px;
    padding: 10px 20px 20px 20px;
}

.redeemablePointsBlk p {
    font-size: 14px;
    font-weight: 600;
}

.redeemablePointsBlk span {
    margin-left: auto;
    font-size: 13px;
    font-weight: 600;
}

.redeemablePointsBlk img {
    width: 20px;
}


/* Coins */
.coinsBlk {
    display:flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
}

.coinsBlk p {
    font-size: 14px;
    font-weight: 600;
}

.coinsBlk span {
    margin-left: auto;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}

.coinsBlk img {
    width: 20px;
}

