.commentButton {
    background: #09172E;
    color: #fff;
    padding: 7px 15px;
    border: 1px solid #2B315C;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
}

.commentButton i {
    font-size: 15px;
}

.commentButton span {
    font-size: 12px;
}