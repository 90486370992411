.hashTagButton {
    background: #0D1F3E;
    border: 1px solid #2B315C;
    padding: 10px 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 600;
}

.hashTagButtonContainer {
    position: relative;
}

.modalContainer {
    position: absolute;
    top: 45px;
    width: 300px;
    max-height: 150px;
    padding: 15px;
    border-radius: 10px;
    background: #143262;
    z-index: 1;
    box-shadow: 0px 0px 5px #0f1c32; 
}


@media screen and (max-width:1200px) {
    .modalContainer {
        margin-left: -210px;
    }
}

.modalContainer::-webkit-scrollbar {
    width: 7px;
}

.modalContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.modalContainer::-webkit-scrollbar-thumb {
    background: rgb(39, 108, 226);
    border-radius: 10px;
    cursor: pointer;
}

.modalContainer h3 {
    font-size: 14px;
    margin-bottom: 10px;
    display:block;
    font-weight: 600;
    color: #fff;
}

.modalContainer h3:last-child {
    margin-bottom: 0px;
}

.hashTagList {
    display:block;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 5px;
    cursor:pointer;
    transition: background-color 0.3s ease-in-out
}
.hashTagList:hover {
    background:#09172E;
}
