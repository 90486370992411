.logoContainerBlk img {
    position: relative;
    top: 4px;
    width: 150px;
}


@media screen and (max-width:768px) {
    .logoContainerBlk img {
        position: relative;
        top: 2px;
        width: 115px;
    }
}