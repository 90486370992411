

.interactionContainer {
    background: #09172E;
    color: #fff;
  
    border: 1px solid #2B315C;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
}

.textButtonBlock {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 7px 20px;
}

.interactionIconContainer {
    background: #132F5D;
    gap: 10px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.20);
    border-radius: 20px;
    padding: 8px 10px;
    width: 280px;
    position: absolute;
    right: 0;
    top: -65px;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.showInteractions {
    opacity: 1;
    transform: scale(1);
    animation: fadeInScale 0.2s ease-in-out;
}

.interactionIconContainer img {
    width: 100%;
    cursor: pointer;
}

.isLiked {
    border: 1px solid #01c2f3;
}

.isLiked .textButtonBlock span,
.isLiked .textButtonBlock i {
    color: #01c2f3;
}

.buttonImage {
    width: 18px;
}

.interactionIconContainer {
    z-index: 9999;
}

.interactionIconContainer div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.interactionIconContainer div:first-child p {
    margin-left:-10px;
}

.interactionIconContainer div:nth-child(2) p {
    margin-left: -11px;
}

.interactionIconContainer div:nth-child(3) p {
    margin-left: -15px;
}

.interactionIconContainer div:nth-child(4) p {
    margin-left: -13px;
}

.interactionIconContainer div:last-child p {
    margin-left: -10px;
}

.reactionLabel {
    position: absolute;
    bottom: -35%;
    left: 50%;
    transform: translateX(0%);
    color: #fff;
    font-size: 10px;
    visibility: hidden;
}

.interactionIconContainer div:hover .reactionLabel {
    visibility: visible;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}