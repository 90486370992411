.navigation {
    background:#040b1f;
    width: 100%;
    position: fixed;
    z-index: 99999;
    padding: 8px 0px;
}

.flexBoxBlk {
    align-items: center;
}

.flexBoxBlk div:nth-child(2) {
    flex: 2;
}

.flexBoxBlk div:last-child {
   margin-left:auto;
}

