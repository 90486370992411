.containerBlock {
    position: absolute;
    right: -10px;
    padding: 10px 30px 15px 20px;
    background: #040b1f;
    top: 40px;
    width: 150px;
    cursor: default;
}

.signoutText {
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
}

.signoutText:hover {
   color:#01C2F3;
}

.profile {
    color: #fff;
    text-decoration: none;
    font-size: 10px;
    display: block;
    font-weight: 600;
    margin-bottom: 14px;
    transition: color 0.3s ease-in-out;
}

.profile:hover {
    color: #01C2F3;
}
